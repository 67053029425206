import React from "react";

import { useReportChartConfig } from "./config/useReportChartConfig";
import {
  ChartCarousel,
  ChartCarouselSkeleton,
} from "../../_common/charts/chart-carousel/ChartCarousel";
import { useCrawlContextData } from "../../crawl-overview/CrawlContext";

export function ReportChartCarousel({
  loading,
  showSegmentsCharts,
}: {
  loading?: boolean;
  showSegmentsCharts?: boolean;
}): JSX.Element | null {
  const { selectedCrawlSegment, crawlSegments } = useCrawlContextData();

  const displaySegmentationCharts =
    !!showSegmentsCharts && crawlSegments.length > 0;

  const chartConfig = useReportChartConfig(displaySegmentationCharts);

  if (loading) {
    return <ChartCarouselSkeleton />;
  }

  // NOTE: If this is the case, we give the user an explanation in the ReportRows sibling component,
  // and don't want to double-up on the same message - Saul.
  if (selectedCrawlSegment && !displaySegmentationCharts) {
    return null;
  }

  return <ChartCarousel config={chartConfig} />;
}
