import { Theme, useTheme } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { getReportCodes } from "../../helpers/getReportCodes";
import { ChartConfig } from "../../../_common/charts/types/ChartConfig";
import { generateSingleReportTrendChart } from "../../../_common/charts/utils/generateSingleReportTrendChartConfig";
import { generateTopSegmentsChart } from "../../../_common/charts/config/getTopSegmentsChart";
import { generateSegmentComparisonChart } from "../../../_common/charts/config/getSegmentComparisonChart";
import { reportsChartsMap } from "./reportsChartsMap";
import { DateFormatter, useDateFormatter } from "@lumar/shared";
import {
  CrawlContextValue,
  useCrawlContextData,
} from "../../../crawl-overview/CrawlContext";
import { ModuleCode } from "../../../graphql";
import { PredefinedChart, isPredefinedChartConfig } from "./types";
import { useRouteMatch } from "react-router";
import { Routes } from "../../../_common/routing/routes";
import { generateSingleCustomReportTrendChart } from "../../../_common/charts/utils/generateSingleCustomReportTrendChartConfig";
import { generateCustomReportSegmentComparisonChart } from "../../../_common/charts/config/getCustomReportSegmentComparisonChart";
import { generateTaskReportTrendChart } from "../../../_common/charts/utils/generateTaskReportTrendChartConfig";
import { TFunction } from "i18next";
import { generateTopSegmentsCustomReportChart } from "../../../_common/charts/config/getTopSegmentsCustomReportChart";

export function useReportChartConfig(
  displaySegmentationCharts: boolean,
): ChartConfig[] | null {
  const { t } = useTranslation("charts");
  const theme = useTheme();
  const dateFormatter = useDateFormatter();
  const crawlContext = useCrawlContextData();

  const reportPageMatch = useRouteMatch<{
    reportTemplateCodeWithTypeCode: string;
  }>({
    path: Routes.Report.ROUTE,
    exact: true,
  });

  const customReportPageMatch = useRouteMatch<{
    customReportTemplateId: string;
  }>({
    path: Routes.CustomReport.ROUTE,
    exact: true,
  });

  const taskReportPageMatch = useRouteMatch<{ taskId: string }>({
    path: Routes.TaskReport.ROUTE,
    exact: true,
  });

  if (reportPageMatch) {
    const { reportTemplateCode } = getReportCodes(
      reportPageMatch.params.reportTemplateCodeWithTypeCode,
    );
    return getReportChartConfig(
      crawlContext,
      reportTemplateCode,
      displaySegmentationCharts,
      theme,
      t,
      dateFormatter,
    );
  } else if (customReportPageMatch) {
    const customReportTemplateId =
      customReportPageMatch.params.customReportTemplateId;
    return getCustomReportChartConfig(
      crawlContext,
      customReportTemplateId,
      displaySegmentationCharts,
      theme,
      t,
      dateFormatter,
    );
  } else if (taskReportPageMatch) {
    const taskId = taskReportPageMatch.params.taskId;
    return getTaskReportChartConfig(taskId, theme, t, dateFormatter);
  }

  return null;
}

function getReportChartConfig(
  crawlContext: NonNullable<CrawlContextValue["data"]>,
  reportTemplateCode: string,
  displaySegmentationCharts: boolean,
  theme: Theme,
  t: TFunction<"charts">,
  dateFormatter: DateFormatter,
): ChartConfig[] {
  const isSegmentApplied = Boolean(crawlContext?.selectedCrawlSegment);

  const match = reportsChartsMap.get(reportTemplateCode) || [
    { predefinedChartType: "reportTrend" },
  ];

  const segmentConfigItem = displaySegmentationCharts
    ? isSegmentApplied
      ? generateSegmentComparisonChart(reportTemplateCode)
      : generateTopSegmentsChart(reportTemplateCode)
    : undefined;

  return [
    ...(segmentConfigItem ? [segmentConfigItem(t)] : []),
    ...match
      .reduce<ChartConfig[]>((result, configItem) => {
        if (!isPredefinedChartConfig(configItem)) {
          return [...result, configItem(t, theme, dateFormatter)];
        }

        const predefinedConfigItem = (() => {
          switch (configItem.predefinedChartType) {
            case PredefinedChart.ReportTrend:
              return generateSingleReportTrendChart(reportTemplateCode);
          }
        })();

        if (!predefinedConfigItem) return result;

        return [
          ...result,
          {
            ...predefinedConfigItem(t, theme, dateFormatter),
            supportedModules:
              configItem.supportedModules || Object.values(ModuleCode),
          },
        ];
      }, [])
      .filter((chart) =>
        (chart.supportedModules || [ModuleCode.Seo]).includes(
          crawlContext.crawlProject.moduleCode,
        ),
      ),
  ];
}

function getCustomReportChartConfig(
  crawlContext: NonNullable<CrawlContextValue["data"]>,
  customReportTemplateId: string,
  displaySegmentationCharts: boolean,
  theme: Theme,
  t: TFunction<"charts">,
  dateFormatter: DateFormatter,
): ChartConfig[] {
  const segmentConfigItem = displaySegmentationCharts
    ? crawlContext.selectedCrawlSegment
      ? generateCustomReportSegmentComparisonChart(customReportTemplateId)(t)
      : generateTopSegmentsCustomReportChart(customReportTemplateId)(t)
    : undefined;

  const predefinedConfigItem = generateSingleCustomReportTrendChart(
    customReportTemplateId,
  )(t, theme, dateFormatter);

  return [segmentConfigItem, predefinedConfigItem].filter((v) => !!v);
}

function getTaskReportChartConfig(
  taskId: string,
  theme: Theme,
  t: TFunction<"charts">,
  dateFormatter: DateFormatter,
): ChartConfig[] {
  const predefinedConfigItem = generateTaskReportTrendChart(taskId)(
    t,
    theme,
    dateFormatter,
  );
  return [predefinedConfigItem];
}
