/* eslint-disable fp/no-this */
import Highcharts from "highcharts";
import { HighchartsChart } from "../../_common/charts/components/HighchartsChart";
import { CreateTaskFormValuesResult } from "../data/useEditTaskFormValues";
import { makeStyles, Theme, useTheme } from "@material-ui/core";
import { renderToString } from "react-dom/server";
import { useReportTemplateUnitFormatter } from "../../_common/locale/format-api-enum/useReportTemplateUnitFormatter";
import { ReportTemplateUnit } from "../../graphql";
import {
  useDateFormatter,
  useTranslation,
  Alert,
  Typography,
} from "@lumar/shared";
import { isDate } from "lodash";
import { TFunction } from "i18next";
import { getTaskCustomReportTrendSeriesConfig } from "../../_common/charts/utils/getTaskCustomReportTrendSeriesConfig";

export function TaskTrendChart({
  task,
  disabled,
}: {
  task: CreateTaskFormValuesResult["task"];
  disabled?: boolean;
}): JSX.Element {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation("taskManager");
  const formatUnit = useReportTemplateUnitFormatter();
  const formatDate = useDateFormatter();

  const unit = task?.reportTemplate?.metadata?.unit ?? ReportTemplateUnit.UrLs;

  const series = getSeries(t, theme, task);

  if (!series.find((x) => x.data && x.data?.length >= 2)) {
    return (
      <div className={classes.noTrendStyle}>
        <NoTrendChart />
        <Typography variant="subtitle3Medium" className={classes.noTrendTitle}>
          {t("editDialog.trend")}
        </Typography>
        <Alert
          severity="info"
          size="small"
          className={classes.noTrendAlert}
          classes={{ message: classes.noTrendAlertMessage }}
        >
          {t("noTrend")}
        </Alert>
      </div>
    );
  }

  const options: Highcharts.Options = {
    accessibility: {
      enabled: !disabled,
    },
    series: series,
    chart: {
      type: "area",
      backgroundColor: "transparent",
      spacing: [16, 16, 16, 16],
    },
    plotOptions: {
      series: {
        lineWidth: 2,
      },
      area: {
        marker: {
          symbol: "circle",
          radius: 4,
          enabled: true,
        },
      },
    },
    legend: {
      enabled: series.length > 1,
    },
    xAxis: {
      type: "datetime",
      title: {
        text: null,
      },
    },
    yAxis: {
      title: {
        text: null,
      },
      gridLineColor: theme.palette.grey[200],
    },
    tooltip: {
      formatter: function () {
        return renderToString(
          <div className={classes.tooltipContainer}>
            <div className={classes.tooltipTextContainer}>
              <div className={classes.tooltipValueText}>
                {formatUnit(this.y || 0, unit)}
              </div>
              <div>
                {formatDate(getCrawlDate(this), {
                  dateStyle: "medium",
                  timeStyle: "short",
                })}
              </div>
              <div>{getLabel(this)}</div>
            </div>
          </div>,
        );
      },
    },
    title: {
      text: t("editDialog.trend"),
      style: {
        fontSize: theme.typography.pxToRem(14),
        fontWeight: "500",
        color: theme.palette.grey[500],
      },
    },
  };

  return <HighchartsChart options={options} />;
}

function getSeries(
  t: TFunction<"taskManager">,
  theme: Theme,
  task: CreateTaskFormValuesResult["task"],
): Highcharts.SeriesAreaOptions[] {
  const isTaggedTask = Boolean(task?.customReports?.length);

  if (!isTaggedTask) {
    return [
      {
        type: "area",
        // eslint-disable-next-line fp/no-mutating-methods
        data: [...(task?.trend ?? [])]
          .filter((x) => x.identified !== null && x.identified !== undefined)
          .map((x) => ({ ...x, crawlDate: new Date(x.crawlFinishedAt) }))
          .sort((a, b) => a.crawlDate.getTime() - b.crawlDate.getTime())
          .splice(-30)
          .map((item) => ({
            x: item.crawlDate.getTime(),
            y: item.identified ?? 0,
            crawlDate: item.crawlDate,
          })),
        color: theme.palette.taskTrendChart.color,
        fillColor: theme.palette.taskTrendChart.fillColor,
      },
    ];
  }

  const allReport = task?.customReports?.find((x) =>
    x.customReportTemplate.code.startsWith("task_all_"),
  );
  const unresolvedReport = task?.customReports?.find((x) =>
    x.customReportTemplate.code.startsWith("task_unresolved_"),
  );
  const resolvedReport = task?.customReports?.find((x) =>
    x.customReportTemplate.code.startsWith("task_resolved_"),
  );

  const taskCustomReportSeriesConfig =
    getTaskCustomReportTrendSeriesConfig(theme);

  return [
    {
      type: "area",
      data: getTrendData(allReport?.trend, t("editDialog.trendAll")),
      name: t("editDialog.trendAll"),
      ...taskCustomReportSeriesConfig.all,
    },
    {
      type: "area",
      data: getTrendData(
        unresolvedReport?.trend,
        t("editDialog.trendUnresolved"),
      ),
      name: t("editDialog.trendUnresolved"),
      stacking: "normal",
      ...taskCustomReportSeriesConfig.resolved,
    },
    {
      type: "area",
      data: getTrendData(resolvedReport?.trend, t("editDialog.trendResolved")),
      stacking: "normal",
      name: t("editDialog.trendResolved"),
      ...taskCustomReportSeriesConfig.unresolved,
    },
  ];
}

function getTrendData(
  trend: { createdAt?: string | null; basic?: number | null }[] | undefined,
  label: string,
): { x: number; y: number; crawlDate: Date; label: string }[] {
  // eslint-disable-next-line fp/no-mutating-methods
  return (trend ?? [])
    .filter(
      (x) => typeof x.basic === "number" && typeof x.createdAt === "string",
    )
    .map((x) => ({ ...x, crawlDate: new Date(x.createdAt || "") }))
    .sort((a, b) => a.crawlDate.getTime() - b.crawlDate.getTime())
    .splice(-30)
    .map((x) => ({
      x: x.crawlDate.getTime(),
      y: x.basic ?? 0,
      crawlDate: x.crawlDate,
      label,
    }));
}

function getCrawlDate(point: Highcharts.Point): Date {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const crawlDate = (point as any).crawlDate;
  return isDate(crawlDate) ? crawlDate : new Date();
}

function getLabel(point: Highcharts.Point): string | undefined {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const label = (point as any).label;
  return typeof label === "string" ? label : undefined;
}

function NoTrendChart(): JSX.Element {
  const theme = useTheme();

  return (
    <svg
      viewBox="0 30 893 252"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ position: "absolute", opacity: 0.2 }}
    >
      <path
        d="M53.4703 260.804L0 270.263V291H900V100H887.007L864.52 177.491L846.53 183.312L782.565 240.794L743.087 234.61L711.105 212.781L679.123 199.684L632.648 123.648L573.681 164.03L513.215 168.032L432.26 230.608L384.286 246.979L273.848 253.164L238.867 234.61L198.39 230.608L153.914 246.979H116.435L67.4625 270.263L53.4703 260.804Z"
        fill={theme.palette.taskTrendChart.fillColor}
      />
      <path
        d="M0 272L53.4701 262.444L67.4622 272L116.435 248.479H153.914L198.389 231.94L238.866 235.983L273.847 254.726L384.285 248.479L432.258 231.94L513.213 168.726L573.679 164.684L632.646 123.889L679.12 200.701L711.102 213.932L743.084 235.983L782.562 242.231L846.526 184.162L864.516 178.282L887.003 100H893"
        stroke={theme.palette.taskTrendChart.color}
        strokeWidth="1.5"
      />
    </svg>
  );
}

const useStyles = makeStyles((theme) => ({
  noTrendStyle: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.grey[500],
    fontSize: theme.typography.pxToRem(14),
    position: "relative",
  },
  noTrendTitle: {
    marginTop: theme.spacing(1.5),
    alignSelf: "flex-start",
  },
  noTrendAlert: {
    position: "absolute",
    borderStyle: "solid",
    padding: theme.spacing(0, 1.5),
  },
  noTrendAlertMessage: {
    fontWeight: 500,
  },
  tooltipContainer: {
    display: "flex",
    background: theme.palette.grey[100],
    alignItems: "center",
    boxShadow: theme.shadows[5],
  },
  tooltipTextContainer: {
    background: "#FFF",
    padding: theme.spacing(1, 2),
  },
  tooltipValueText: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.primary,
  },
}));
